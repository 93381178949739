import type { NextPage } from 'next'
import { Form, Input, Button, Card, Badge} from 'antd'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { loginRequest } from 'store/actions'
import { useEffect, useState } from 'react'
// import Link from 'next/link'
import { ITextInterface } from 'types'
import TooltipPassword from './tooltip-password'
import ErrorLogin from './error-login'
import service from 'store/service'

interface IAnnouncement {
  success: boolean
  message: string
  data: {
    announce_text: string
    active: boolean
  }
}

// interface ITextInterface {
//   size: string
//   weight: string
//   top: string
// }
const Login: NextPage = () => {
  const dispatch = useDispatch()
  const [errorLogin, setErrorLogin] = useState(false)
  const state: any = useSelector<any>((state) => ({
    login: state.authentication.login,
  }))

  const [showAnnouncement, setShowAnnouncement] = useState<boolean>(true)
  const [announcement, setAnnouncement] = useState<string | undefined>(undefined)
  const onFinish = (values: any) => {
    try {
      dispatch(loginRequest(values))
    } catch (e) {
      //alert
    }
  }

  function fetchAnnouncement() {
    try {
      fetch(service.announcement).then((response: any) => response.json()).then((response: IAnnouncement) => {
        if (response.success) {
          if (response.data?.active) {
            setAnnouncement(response.data?.announce_text)
            setShowAnnouncement(true)
          } else {
            setAnnouncement(undefined)
            setShowAnnouncement(false)
          }
        }
      })
    } catch (e) {
      setAnnouncement(undefined)
      setShowAnnouncement(false)
    }
  }

  // const onFinishFailed = (errorInfo: any) => {
  //   console.log('Failed:', errorInfo)
  // }


  useEffect(() => {
    // if (state.login.loading) {
    //   router.push('/home')
    // }

    if (state.login.errorStatus) {
      setErrorLogin(true)
      //   Swal.fire({
      //     title: 'The username or password is incorrect.',
      //     icon: 'error',
      //     iconHtml: '',
      //     showConfirmButton: true,
      //   })
    }
  }, [state])

  useEffect(() => {
    fetchAnnouncement()
  }, [])


  return (
    <div>
      {errorLogin ? (
        <ErrorLogin />
      ) : (
        <div>
          {
            showAnnouncement && announcement && <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <Card size="small" style={{ maxWidth: 800 }}>
                <CardContent dangerouslySetInnerHTML={{ __html: announcement}} />
              </Card>
            </div>
          }

          <HeadTextStyle size="42px" weight="400" top="30px">
            login
          </HeadTextStyle>

          <ContentFormStyled>
            {/* <InputStyled placeholder="Login" />
      <InputStyled placeholder="Password" /> */}

            <FormStyled
              name="basic"
              layout="vertical"
              // labelCol={{ span: 8 }}
              // wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
              requiredMark={'optional'}
            >
              <FormLabelStyled
                label={
                  <label style={{ color: 'white', fontWeight: 'bold' }}>
                    Login
                  </label>
                }
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                    type: 'email',
                  },
                ]}
              >
                <InputStyled placeholder="Email" />
              </FormLabelStyled>

              <FormLabelStyled
                tooltip={() => <TooltipPassword />}
                label={
                  <label style={{ color: 'white', fontWeight: 'bold' }}>
                    Password
                  </label>
                }
                name="password"
                rules={[
                  { required: true, message: 'Please input your password!' }
                ]}
              >
                <InputPasswordStyled placeholder="Password" />
              </FormLabelStyled>
              <LineForgetStyled>
                {/* <Form.Item name="remember" valuePropName="checked">
              <Checkbox style={{ color: 'white' }}>Remember me</Checkbox>
            </Form.Item> */}
                <Button
                  type="link"
                  style={{ color: 'white' }}
                  href="/forget-password"
                >
                  Forgot password?
                </Button>
                {/* <Link href="/forget-password">Forgot password?</Link> */}
              </LineForgetStyled>

              <Form.Item>
                <Button
                  htmlType="submit"
                  shape="round"
                  type="primary"
                  className="link"
                  style={{
                    width: '150px',
                    fontWeight: 'bold',
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </FormStyled>
          </ContentFormStyled>
        </div>
      )}
    </div>
  )
}

export default Login

const FormStyled = styled(Form)`
  /* max-width: 50vw; */
  text-align: center;
  width: 100%;
  max-width: 400px;
`
const InputStyled = styled(Input)`
  border: 0 0 1px 0;
  background-color: transparent !important;
  border-color: transparent;
  color: white;
  border-bottom: 1px solid darkgrey;
  &:hover,
  &:focus {
    border-color: transparent !important;
    border-bottom: 1px solid darkgrey;
  }
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    background-color: transparent !important;
  }
`

const HeadTextStyle = styled.p`
  font-size: ${(props: ITextInterface) => props.size};
  font-weight: ${(props: ITextInterface) => props.weight};
  margin-top: ${(props: ITextInterface) => (props.top ? props.top : 0)};
  margin-bottom: 23px;
  text-transform: uppercase;
  color: white;
`

const ContentFormStyled = styled.div`
  maxwidth: 500px;
  display: inline-flex;
  justify-content: center;
  minheight: 400;
  height: 100%;
  width: 100%;
  borderradius: 20px;
`

const FormLabelStyled = styled(Form.Item)`
  color: white;
  .label {
    color: white;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  /* .ant-tooltip-inner {
    color: yellow;
    background-color: green;
    width: 800px;
  } */
  .ant-form-item-label > label .ant-form-item-tooltip {
    color: white !important;
  }
`
const InputPasswordStyled = styled(Input.Password)`
  background-color: transparent !important;
  border-color: transparent;
  border-bottom: 1px solid darkgrey;
  color: white;

  .ant-input-password-icon {
    color: white;
  }
  &:hover,
  &:focus {
    border-color: transparent;
    border-bottom: 1px solid darkgrey;
  }

  .ant-input {
    color: white;

    background-color: transparent !important;
  }
`

const LineForgetStyled = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
`

const CardContent = styled.div`
  padding: 20px;
    
  p {
      margin-bottom: 10px;
      font-size: 16px;
      color: #160D08;
  }

  h1 {
      margin-bottom: 20px;
      font-size: 24px;
      color: #160D08;
  }
`;