import React, { useEffect, useState } from 'react'
import { Card, Form, Input, Button } from 'antd'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { resetPasswordRequest } from 'store/actions'
import { ITextInterface } from 'types'
import TooltipPassword from './tooltip-password'
// import { useRouter } from 'next/router'
import Link from 'next/link'

const SetNewPassword = ({ email }: any) => {
  // const router = useRouter()

  // const [submitted, setSubmitted] = useState(false)
  const [passwordIncorrect, setPasswordIncorrect] = useState(true)
  const dispatch = useDispatch()
  const state: any = useSelector<any>((state) => ({
    forgetPassword: state.authentication.forgetPassword,
  }))
  const onFinish = (values: any) => {
    dispatch(resetPasswordRequest(values))
  }

  useEffect(() => {
    // console.log(state.forgetPassword)
    //   if (state.forgetPassword.status === 'Failed') {
    //     Swal.fire({
    //       title: 'Reset Password Failed',
    //       icon: 'error',
    //       iconHtml: '',
    //       showConfirmButton: true,
    //     })
    //   } else if (state.forgetPassword.status === 'Success') {
    //     Swal.fire({
    //       title: 'Reset Password Success',
    //       icon: 'success',
    //       iconHtml: '',
    //       showConfirmButton: true,
    //     })
    //   }
  }, [state])

  // const onFinishFailed = (errorInfo: any) => {}
  return (
    <Card bordered={false} className="forget-password-card">
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '2rem' }}
      >
        <HeadTextStyle size="24px" weight="400">
          Set New Password
        </HeadTextStyle>
        <ContentFormStyled>
          <FormStyled
            name="basic"
            layout="vertical"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            // requiredMark={'optional'}
          >
            <FormLabelStyled
              label={<label style={{ color: '#428BCB' }}>Email</label>}
              // rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <p
                style={{
                  color: '#428BCB',
                  textAlign: 'start',
                  marginBottom: 0,
                }}
              >
                {email.email}
              </p>
              {/* <InputStyled placeholder="Enter your email" /> */}
            </FormLabelStyled>
            <FormLabelStyled
              label={<label style={{ color: '#428BCB' }}>New Password</label>}
              name="new_password"
              tooltip={() => <TooltipPassword />}
              rules={[
                { required: true, message: 'Please input your password!' },
                () => ({
                  validator(_, value) {
                    if (!value) { return Promise.reject() }
                    const regex =
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
                    if (regex.test(value)) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(
                        'Password should be 8 characters minimum, with at least 1 upper case, 1 lower case and 1 numeric character'
                      )
                    )
                  },
                }),
              ]}
            >
              <InputPasswordStyled placeholder="New Password" />
            </FormLabelStyled>
            <FormLabelStyled
              label={
                <label style={{ color: '#428BCB' }}>Confirm Password</label>
              }
              name="confirm_password"
              dependencies={['new_password']}
              rules={[
                {
                  required: true,
                  message: 'Please input your confirm password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject()
                    } else if (
                      value &&
                      getFieldValue('new_password') === value
                    ) {
                      setPasswordIncorrect(false)
                      return Promise.resolve()
                    }
                    setPasswordIncorrect(true)
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!'
                      )
                    )
                  },
                }),
              ]}
            >
              <InputPasswordStyled placeholder="Confirm Password" />
            </FormLabelStyled>
            <Form.Item style={{ marginBottom: 0 }}>
              <Link href="/login" passHref>
                <Button
                  shape="round"
                  type="primary"
                  className="warning"
                  style={{
                    width: '150px',
                    margin: '5px 10px',
                    fontWeight: 'bold',
                  }}
                >
                  Cancel
                </Button>
              </Link>
              {/* <Button
                htmlType="submit"
                shape="round"
                style={{ width: '150px', color: '#276ba7', fontWeight: 'bold' }}
              >
                Submit
              </Button> */}
              <Button
                htmlType="submit"
                shape="round"
                type="primary"
                style={{
                  width: '150px',
                  margin: '5px 10px',
                  fontWeight: 'bold',
                }}
                disabled={passwordIncorrect}
              >
                Submit
              </Button>
            </Form.Item>
          </FormStyled>
        </ContentFormStyled>
      </div>
    </Card>
  )
}
export default SetNewPassword

const HeadTextStyle = styled.p`
  font-size: ${(props: ITextInterface) => props.size};
  font-weight: ${(props: ITextInterface) => props.weight};
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  color: #276ba7;
`

const ContentFormStyled = styled.div``

const FormStyled = styled(Form)`
  /* max-width: 50vw; */
  text-align: center;
`

const FormLabelStyled = styled(Form.Item)`
  color: black;
  .label {
    color: black;
  }
`

const InputPasswordStyled = styled(Input.Password)`
  background-color: transparent;
  border-color: transparent;
  border: 1px solid darkgrey;
  border-radius: 30px;

  &:hover,
  &:focus {
    border-color: transparent;
    border: 1px solid darkgrey;
  }

  .ant-input {
    background-color: transparent;
  }
`
