import type { NextPage } from 'next'
import { Form, Input, Button } from 'antd'
import styled from 'styled-components'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { registerRequest } from 'store/actions'
import TooltipPassword from './tooltip-password'

interface ITextInterface {
  size: string
  weight: string
  top: string
}
const Register: NextPage = () => {
  const router = useRouter()

  const dispatch = useDispatch()
  const state: any = useSelector<any>((state) => ({
    register: state.authentication.register,
  }))
  const onFinish = (values: any) => {
    try {
      dispatch(registerRequest(values))
    } catch (e) {}
  }

  // const onFinishFailed = (errorInfo: any) => {}
  useEffect(() => {
    if (state.register.loading) {
      router.push('/login')
    }
  }, [state])
  return (
    <div>
      <HeadTextStyle size="42px" weight="400" top="30px">
        register
      </HeadTextStyle>
      <ContentFormStyled>
        {/* <InputStyled placeholder="Login" />
      <InputStyled placeholder="Password" /> */}

        <FormStyled
          name="basic"
          layout="vertical"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          requiredMark={'optional'}
        >
          <FormLabelStyled
            label={<label style={{ color: 'white' }}>Email</label>}
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
                type: 'email',
              },
            ]}
          >
            <InputStyled placeholder="Enter your email" />
          </FormLabelStyled>
          <FormLabelStyled
            tooltip={() => <TooltipPassword />}
            required
            label={<label style={{ color: 'white' }}>Password</label>}
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
              () => ({
                validator(_, value) {
                  const regex =
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
                  if (regex.test(value)) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(
                      'Password should be 8 characters minimum,with at least 1 upper case, 1 lower case and 1 numeric character'
                    )
                  )
                },
              }),
            ]}
          >
            <InputPasswordStyled placeholder="Enter your password" />
          </FormLabelStyled>
          <Form.Item
            label={<label style={{ color: 'white' }}>Confirm Password</label>}
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please confirm your confirm password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  )
                },
              }),
            ]}
            // rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <InputPasswordStyled placeholder="Re-enter your password" />
          </Form.Item>
          <FormLabelStyled
            label={<label style={{ color: 'white' }}>Username</label>}
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <InputStyled placeholder="Enter your username" />
          </FormLabelStyled>
          <FormLabelStyled
            label={<label style={{ color: 'white' }}>PIN</label>}
            name="secure_password"
            rules={[
              { required: true, message: 'Please input your secure password!' },
              { len: 4, message: 'PIN must have 4 digit' },
            ]}
          >
            <InputPasswordStyled
              placeholder="Enter your PIN"
              type="number"
              maxLength={4}
            />
          </FormLabelStyled>
          <Form.Item>
            <Button
              htmlType="submit"
              shape="round"
              style={{ width: '150px', color: '#276ba7', fontWeight: 'bold' }}
            >
              Submit
            </Button>
          </Form.Item>
        </FormStyled>
      </ContentFormStyled>
    </div>
  )
}

export default Register

const FormStyled = styled(Form)`
  /* max-width: 50vw; */
  text-align: center;
  width: 400px;
`
const InputStyled = styled(Input)`
  border: 0 0 1px 0;
  background-color: transparent !important;
  border-color: transparent;
  color: white;
  border-bottom: 1px solid darkgrey;
  &:hover,
  &:focus {
    border-color: transparent;
    border-bottom: 1px solid darkgrey;
  }
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    background-color: transparent !important;
  }
`

const HeadTextStyle = styled.p`
  font-size: ${(props: ITextInterface) => props.size};
  font-weight: ${(props: ITextInterface) => props.weight};
  margin-top: ${(props: ITextInterface) => (props.top ? props.top : 0)};
  margin-bottom: 23px;
  text-transform: uppercase;
  color: white;
`

const ContentFormStyled = styled.div`
  justify-content: center;
  display: flex;
`

const FormLabelStyled = styled(Form.Item)`
  color: white;
  .label {
    color: white;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .ant-form-item-label > label .ant-form-item-tooltip {
    color: white !important;
  }
`

const InputPasswordStyled = styled(Input.Password)`
  background-color: transparent !important;
  border-color: transparent;
  color: white;

  border-bottom: 1px solid darkgrey;
  &:hover,
  &:focus {
    border-color: transparent;
    border-bottom: 1px solid darkgrey;
  }
  .ant-input-password-icon {
    color: white;
  }

  .ant-input {
    color: white;
    background-color: transparent !important;
  }
`
