import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EAuthenticationTypes } from 'store/actionTypes'
import styled from 'styled-components'
import { Form, Input } from 'antd'

const Pin = ({ length = 4, onChange }: any) => {
  let pinKeys: string[] = []
  let initialPin = []
  for (let idx = 0; idx < length; idx++) {
    const key = `pin${idx}`
    pinKeys.push(key)
    let value: any = {}
    value[key] = ''
    initialPin.push(value)
  }

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [status, setStatus] = useState(false)
  const state: any = useSelector<any>((state) => ({
    forgetPassword: state.authentication.forgetPassword,
  }))
  form.setFieldsValue(initialPin)

  const checkNumber = (e: any) => {
    // true == number or backspace
    return (e.charCode >= 48 && e.charCode <= 57) ||
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      e.key === 'Backspace'
      ? true
      : false
  }

  const handleKeyPress = (e: any) => {
    if (!checkNumber(e)) {
      e.preventDefault()
    }
  }

  const handleKeyUp = (e: any) => {
    if (!checkNumber(e)) {
      return
    }

    const currentId = e.target.id
    const currentIdx = pinKeys.findIndex((key) => key === currentId)
    let focusIdx = currentIdx

    if (e.key === 'Backspace') {
      if (currentIdx !== 0) {
        focusIdx--
      }
    } else {
      if (currentIdx < length - 1) {
        focusIdx++
      }
    }
    const newId = pinKeys[focusIdx]

    setTimeout(() => {
      document.getElementById(newId)?.focus()
    })
  }

  const onFormLayoutChange = (changedValues: any, allValues: any) => {
    if (state.forgetPassword.data_pin) {
      dispatch({
        type: EAuthenticationTypes.PIN_PASSWORD_STATUS,
        payload: '',
      })
    }
    if (!pinKeys.every((key) => allValues[key])) {
      return
    }
    let pin = ''
    pinKeys.forEach((key) => {
      pin = pin + String(allValues[key])
    })
    onChange(pin)
  }

  useEffect(() => {
    if (
      state.forgetPassword.data_pin === 404 ||
      state.forgetPassword.data_pin === '404'
    ) {
      setStatus(true)
      form.resetFields()
      setTimeout(() => {
        document.getElementById(pinKeys[0])?.focus()
      }, 250)
    } else {
      setStatus(false)
    }
  }, [state])

  return (
    <div>
      <Form
        form={form}
        onValuesChange={onFormLayoutChange}
        layout="inline"
        style={{ justifyContent: 'center' }}
      >
        {pinKeys.map((key, idx) => {
          return (
            <Form.Item name={key} key={idx}>
              <Input
                type="text"
                className={!status ? 'no-animation' : 'animation'}
                maxLength={1}
                style={styles}
                onKeyUp={handleKeyUp}
                onKeyPress={handleKeyPress}
              />
            </Form.Item>
          )
        })}
      </Form>
      {state.forgetPassword.data_pin === 404 && (
        <TextStyle>Incorrect PIN</TextStyle>
      )}
    </div>
  )
}

export { Pin }

const TextStyle = styled.p`
  color: red;
`

const styles: any = {
  width: '65px',
  height: '87px',
  padding: '8px',
  margin: '10px',
  fontSize: '24px',
  textAlign: 'center',
  borderRadius: '18px',
}
