import type { NextPage } from 'next'
import styled from 'styled-components'
import { Card, Button } from 'antd'
import { ITextInterface } from 'types'
import { CorrectsIcon } from 'components/icons'
const NotiConfirm: NextPage = () => {
  return (
    <CardDesignStyled
      bordered={false}
      style={{
        width: 600,
        display: 'inline-flex',
        height: 500,
        borderRadius: 20,
      }}
    >
      <div style={{ height: '100%', width: '100%' }}>
        <NotiStyle color="white">
          <MessageStyle>
            <CorrectsIcon />
            <TextStyle size="36px" color="white">
              SUCCESS
            </TextStyle>
          </MessageStyle>
          <SuccessStyle>
            <TextStyle size="24px" color="#276ba7">
              Congratulation, your account password has been changed.
            </TextStyle>
            <Button
              type="primary"
              shape="round"
              style={{ width: 150 }}
              href="/login"
            >
              Done
            </Button>
          </SuccessStyle>
        </NotiStyle>
      </div>
    </CardDesignStyled>
  )
}
export default NotiConfirm

const MessageStyle = styled.div`
  padding-top: 20px;
  background-color: #73c088;
  border-radius: 20px 20px 0 0;
  height: 50% !important;
`
const TextStyle = styled.p`
  font-size: ${(props: ITextInterface) => props.size};
  color: ${(props: ITextInterface) => props.color};
`
const CardDesignStyled = styled(Card)`
  width: 600;
  display: 'inline-flex';
  height: 500;
  border-radius: 20;
  .ant-card-body {
    /* width: 100% !important; */
    /* margin: auto; */
    padding: 0px;
  }
`

const NotiStyle = styled.div`
  /* height: 50% !important; */
  border-radius: 20px;
  background-color: ${(props: ITextInterface) => props.color}; ;
`

const SuccessStyle = styled.div`
  margin: 4rem 6rem 0 6rem;
`