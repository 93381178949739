import React from 'react'
import { Pin } from './pin/pin'
import { useDispatch } from 'react-redux'
import { checkPinRequest } from 'store/actions'
import styled from 'styled-components'
import { ITextInterface } from 'types'
import { LeftOutlined } from '@ant-design/icons'
import { Card, Button } from 'antd'
import { EAuthenticationTypes } from 'store/actionTypes'

const PinPassword = () => {
  const dispatch = useDispatch()
  // const [errorPin, setErrorPin] = useState(false)
  // const state: any = useSelector<any>((state) => ({
  //   forgetPassword: state.authentication.forgetPassword,
  // }))

  const handleChange = (value: any) => {
    if (value.length === 4) {
      dispatch(checkPinRequest(value))
    }
  }

  const backForgotPassword = () => {
    dispatch({
      type: EAuthenticationTypes.SET_TAB_NEW_PASSWORD,
      payload: 0,
    })
    dispatch({
      type: EAuthenticationTypes.CLEAR_PIN,
      payload: '',
    })
  }

  // useEffect(() => {
  //   if (state.forgetPassword.data_pin === 404) {
  //     setErrorPin(true)
  //   }
  // }, [state])

  return (
    <Card bordered={false} className="forget-password-card">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <BackStyle
          style={{ textAlign: 'start', paddingTop: '20px', color: '#133072' }}
        >
          <Button type="link" onClick={backForgotPassword}>
            <LeftOutlined style={{ cursor: 'pointer' }} />
            Back
          </Button>
          {/* <LeftOutlined style={{ cursor: 'pointer' }} />
          <span style={{ marginLeft: '15px', cursor: 'pointer' }}>Back</span> */}
        </BackStyle>
        <ContentStyle>
          <TextStyle size="24px">Input your 4-digit PIN</TextStyle>
          <Pin length={4} onChange={handleChange} />
        </ContentStyle>
        <hr style={{ marginTop: '20px' }} />
        <SupportStyle>
          <TextStyle size="14px" bottom="0px" align="start">
            For any questions or problems please email us at
          </TextStyle>
          <TextStyle size="20px" bottom="0px" align="start">
            support@da.co.th
          </TextStyle>
        </SupportStyle>
      </div>
    </Card>
  )
}
export default PinPassword

const TextStyle = styled.p`
  font-size: ${(props: ITextInterface) => props.size};
  color: #428bcb;
  text-align: ${(props: ITextInterface) => props.align};
  margin-bottom: ${(props: ITextInterface) => props.bottom};
`

const BackStyle = styled.div`
  color: #133072;
`

const ContentStyle = styled.div`
  padding: 30px 0;
  margin-top: 1rem;
`

const SupportStyle = styled.div`
  padding: 1.5rem;
`
