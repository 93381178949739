import styled from 'styled-components'
import { ITextInterface } from 'types'

const TooltipPassword = () => {
  return (
    <div>
      <TextStyle bottom="0" size="16px">
        Password must:
      </TextStyle>
      <ul className="my-0 py-0 ml-4 mr-4" style={{ marginLeft: 20 }}>
        <li>At least 8 character in length</li>
        <li>
          Should contain:
          <div style={{ marginLeft: 20 }}>
            <TextStyle bottom="0" size="12px">
              lower case letters (a-z)
            </TextStyle>
            <TextStyle bottom="0" size="12px">
              upper case letters (A-Z)
            </TextStyle>
            <TextStyle bottom="0" size="12px">
              number (i.e. 0-9)
            </TextStyle>
          </div>
        </li>
      </ul>
    </div>
  )
}
export default TooltipPassword

const TextStyle = styled.p`
  font-size: ${(props: ITextInterface) => props.size};
  margin-bottom: ${(props: ITextInterface) => props.bottom};
`
