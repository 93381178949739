import type { NextPage } from 'next'
// import Image from 'next/image'
import Login from './login'
import Register from './register'
import styled from 'styled-components'
import { useRouter } from 'next/router'
// import Logo from 'assets/logo-iwpc-navy-login.svg'
import ForgetPassword from './forgetPassword'
import { Pc4Icon } from 'components/icons'

const Signon: NextPage = ({}) => {
  const router = useRouter()

  return (
    <BodySignonStyled>
      <ContentSignonStyled>
        {/* <Image
          src={Logo.src}
          alt="Picture of the author"
          width={274}
          height={89}
        /> */}
        <LogoStyled>
          <Pc4Icon style={{ width: '20vh', height: 'auto', color: 'white' }} />
        </LogoStyled>
        {/* <ContentStyles>
          <Login />
        </ContentStyles> */}
        <ContentStyles>
          {router && router.pathname === '/login' ? (
            <Login />
          ) : router && router.pathname === '/register' ? (
            <Register />
          ) : (
            <ForgetPassword />
          )}
        </ContentStyles>

        <CompanyStyled>
          <TextStyled>Digital Associates Co., Ltd.</TextStyled>
          <TextStyled>
            3/24 Soi Phaholyothin 13, Phaholyothin Rd. Phayatai, Phayatai,
            Bangkok 10400 Thailand.
          </TextStyled>
          <TextStyled>
            <b>Telephone :</b> 02-6182-266 <b>Fax :</b> 02-6182-260{' '}
            <b>Website :</b>
            http://www.da.co.th
          </TextStyled>
        </CompanyStyled>
      </ContentSignonStyled>
    </BodySignonStyled>
  )
}

export default Signon

const BodySignonStyled = styled.div`
  background-color: #276ba7;
  width: 100%;
  /* height: 100%; */
  height: 100vh;
`
const ContentSignonStyled = styled.div`
  text-align: center;
  padding: 1rem 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: none;
`
const CompanyStyled = styled.div`
  margin-top: auto;
`

const TextStyled = styled.p`
  margin-bottom: 0;
  color: white;
`
const ContentStyles = styled.div`
  // min-height: 65vh;
  margin: 3rem 0;
`

const LogoStyled = styled.div`
  margin: 1.2rem auto;
`
