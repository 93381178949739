import type { NextPage } from 'next'
import { useState, useEffect } from 'react'
import { Card, Form, Input, Button } from 'antd'
import styled from 'styled-components'
import PinPassword from './pinPassword'
import SetNewPassword from './setNewPassword'
import { useDispatch, useSelector } from 'react-redux'
import { checkEmailRequest } from 'store/actions'
import Link from 'next/link'
import { ITextInterface } from 'types'
import { Helper } from 'utils/helper'
import NotiConfirm from './noti-confirm'
import { EAuthenticationTypes } from 'store/actionTypes'

const ForgetPassword: NextPage = () => {
  const [form] = Form.useForm()
  const [emailStatus, setEmailStatus] = useState(false)

  const dispatch = useDispatch()
  const state: any = useSelector<any>((state) => ({
    forgetPassword: state.authentication.forgetPassword,
  }))

  const onFinish = (values: any) => {
    if (values.email) {
      dispatch(checkEmailRequest(values))
    }
  }

  // const onFinishFailed = (errorInfo: any) => {
  // console.log('Failed:', errorInfo)
  // }

  useEffect(() => {
    if (state.forgetPassword.statusEmail !== '') {
      form.setFields([
        {
          name: 'email',
          errors: ['Email is not found in system'],
        },
      ])
      setEmailStatus(false)
    }
  }, [state])
  const checkMail = (value: any) => {
    const checkEmail = Helper.checkEmailFormat(value)
    //correct -> true,incorrect -> false
    setEmailStatus(checkEmail)
    if (!checkEmail) {
      return Promise.reject('Please check your input email ')
    }
    return Promise.resolve()
  }
  const clearError = () => {
    dispatch({
      type: EAuthenticationTypes.STATUS_CHECK_EMAIL,
      payload: '',
    })
  }
  return (
    <div className="site-card-border-less-wrapper mt-10">
      {state.forgetPassword.tab === 0 && (
        <Card bordered={false} className="forget-password-card">
          <div
            style={{
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              flexDirection: 'column',
            }}
          >
            <div style={{ textAlign: 'start' }}>
              <TextStyle size="20px">Forget Password</TextStyle>
              <TextStyle size="14px" style={{ marginBottom: 75 }}>
                Enter your e-mail address associated with your account.
              </TextStyle>
            </div>

            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="email"
                rules={[
                  // {
                  //   required: true,
                  //   message: 'Please input your email!',
                  //   type: 'email',
                  // },
                  {
                    validator: (_, value) => checkMail(value),
                  },
                ]}
                hasFeedback
                //   hasFeedback={form.getFieldValue('email') ? true : false}
                validateStatus={emailStatus ? 'success' : 'error'}
              >
                <InputStyled
                  placeholder="Email address"
                  onChange={clearError}
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Link href="/login" passHref>
                  <Button
                    shape="round"
                    type="primary"
                    className="warning"
                    style={{
                      width: '150px',
                      margin: '5px 10px',
                      fontWeight: 'bold',
                    }}
                  >
                    Cancel
                  </Button>
                </Link>

                <Button
                  htmlType="submit"
                  shape="round"
                  type="primary"
                  style={{
                    width: '150px',
                    margin: '5px 10px',
                    fontWeight: 'bold',
                  }}
                  disabled={!emailStatus}
                >
                  Next
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Card>
      )}{' '}
      {state.forgetPassword.tab === 1 && <PinPassword />}
      {state.forgetPassword.tab === 2 && (
        <SetNewPassword email={state.forgetPassword.email} />
      )}
      {state.forgetPassword.tab === 3 && <NotiConfirm />}
    </div>
  )
}

export default ForgetPassword

const InputStyled = styled(Input)`
  border: 0 0 1px 0;
  background-color: transparent;
  border-color: transparent;
  border-bottom: 1px solid darkgrey;
  &:hover,
  &:focus {
    border-color: transparent;
    border-bottom: 1px solid darkgrey;
  }
`

const TextStyle = styled.p`
  font-size: ${(props: ITextInterface) => props.size};
  color: #428bcb;
`

// const FormLabelStyled = styled(Form.Item)`
//   color: white;
//   .label {
//     color: white;
//   }
// `
